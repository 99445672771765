<script>
    export let pageLabel;

    let videos = [
        "https://clay.dust.supply/video/clip1.mp4",
        "https://clay.dust.supply/video/clip2.mp4",
        "https://clay.dust.supply/video/clip3.mp4",
        "https://clay.dust.supply/video/clip4.mp4",
        "https://clay.dust.supply/video/clip5.mp4",
        "https://clay.dust.supply/video/clip6.mp4",
        "https://clay.dust.supply/video/clip7.mp4",
        "https://clay.dust.supply/video/clip8.mp4",
        "https://clay.dust.supply/video/clip9.mp4"
    ]
</script>

<svelte:head>
    <title>{pageLabel}</title>
</svelte:head>

<h1>Motion</h1>

<div class="container">
    {#each videos as video}
        <video
            src={video}
            autoplay 
            loop >
            <track kind="captions">
        </video>
    {/each}
</div>

<style>
    .container {
        width: 100%;
        min-height: 100%;
        position: absolute;
        z-index: 0;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    h1 {
        margin-top: 10rem;
        font-size: 20vw;
        z-index: 1;
        mix-blend-mode: difference;
        position: fixed;
    }
    video {
        max-width: 100%
    }
</style>