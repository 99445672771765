<script>

	import { Scrawl, Scratchings, Motion } from './components'
	import { PageTransition } from './functions'

	let landed = false;
	let trackPlaying = false;
	let playedAudio = ["SILENCE"];

	let pageOptions = [
		{ page: "CLAY | SCRAWL", component: Scrawl},
		{ page: "CLAY | SCRATCHINGS", component: Scratchings },
		{ page: "CLAY | MOTION", component: Motion },
	];
	let selected = pageOptions[0];

	function launch() {
		landed = true;
		let audio = new Audio("audio/audio.mp3");
		audio.volume = 0.2;
		audio.loop = true;
		audio.play();
		trackPlaying = true;
	}

	function appendAudio() {
		let samples = ["SCRATCH", "BITE", "TEAR", "PAIN", "HURT", "CRASH"]
		let selected = Math.floor(Math.random() * samples.length)
		playedAudio.push(samples[selected])
		if (playedAudio.length >= 9)  {
			playedAudio = playedAudio.splice(1,9)
		} else {
			playedAudio = playedAudio
		}
	}

	window.onclick = function() {
		let audioOptions = ['audio/1.mp3', 'audio/2.mp3', 'audio/3.mp3', 'audio/4.mp3', 'audio/5.mp3'];
		let selectedAudio = audioOptions[Math.round(Math.random())];
		let aud = new Audio(selectedAudio)
		aud.volume = 1
		aud.play()
		appendAudio()
	}

	function change(page) {
		selected = pageOptions[page]
	}

	function visitPage(url) {
		window.open(url, '_blank').focus();
	}

</script>

<svelte:head>
	<title>CLAY</title>
</svelte:head>

{#if !landed}
	<container on:click={()=>launch()} style="cursor: pointer;">
		<PageTransition refresh={landed}>
			<h1>Dig</h1>
			<img src="image/cover.jpg" alt="cover">
		</PageTransition>
	</container>
{:else}
	<container>
		<PageTransition refresh={selected}>

			<svelte:component
			this={selected.component}
			pageLabel={selected.page} 
		/>
		</PageTransition>
	</container>

	<menu>
		<button on:click={()=>change(0)}><h2>走り書き</h2></button>
		<button on:click={()=>change(1)}><h2>引っかき傷</h2></button>
		<button on:click={()=>change(2)}><h2>モーション</h2></button>
		<button on:click={()=>visitPage("https://water.dust.supply")}><h2 class="alter">WATER</h2></button>
		<button on:click={()=>visitPage("https://ceramic.dust.supply")}><h2 class="alter"><s>CERAMIC</s></h2></button>
		<button on:click={()=>visitPage("https://dust.supply")}><h2 class="alter">DUST</h2></button>
	</menu>

	<played>
		{#each playedAudio as aud}
			<p>{aud}</p>
		{/each}
	</played>

	<bottom>
		<h2 class="alter">Clay</h2>
		<h2 class="alter"><a href="https://distro.dust.supply/mould_me">Listen</a></h2>
	</bottom>
{/if}

<style>
	container {
		width: 100%;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	menu {
		position: fixed;
		top: 0px;
		left: 0px;
		margin-left: -3rem;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		z-index: 1;
	}
	played {
		position: fixed;
		bottom: 0px;
		right: 10px;
		display: flex;
		flex-direction: column;
		text-align: right;
		font-family: "UnifrakturMaguntia";
		text-transform: lowercase;
		z-index: 1;
	}
	bottom {
		position: fixed;
		bottom: 10px;
		left: 10px;

	}
	h1 {
		font-size: 10rem;
	}

	button {
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid red;
		color: white;
		cursor: pointer;
		height: 3rem;
		width: 150px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.alter {
		font-family: "UnifrakturMaguntia";
		text-transform: lowercase;
	}

	img {
		width: 50%
	}
	a {
		text-decoration: none;
		color: inherit;
	}
</style>