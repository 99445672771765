<script>
    export let pageLabel;

    let selectedImage = "image/cover.jpg";

    let images = [
        "image/640px-A_depiction_of_clay_for_pot_making.jpg",
        "image/640px-Clay_blockhead_0.jpg",
        "image/640px-Clay_blockhead_4.jpg",
        "image/640px-Clay_for_Tiles.jpg",
        "image/640px-Maras_-_clay.jpg",
        "image/640px-Natural_Clay_Art_at_Dummas_Beach_Surat_1.jpg",
        "image/640px-Skourta_clay_pit_05.jpg",
        "image/640px-Skourta_clay_pit_12.jpg",
        "image/640px-Skourta_clay_pit_18.jpg",
        "image/640px-Skourta_clay_pit_21.jpg",
        "image/640px-Skourta_clay_pit_24.jpg",
        "image/640px-Soft_clay_capping_a_flint_meganodule_(Vanport_Flint,_Middle_Pennsylvanian;_Nethers_Flint_Quarries,_Flint_Ridge,_Ohio,_USA)_1_(30419933454).jpg",
        "image/Big_Clay_No._4_in_Moscow._2021,_Bolotnaya_Embankment._Close-up_3.jpg",
        "image/cover.jpg",
        "image/Mason_bee_nest_hole_damage_in_clay_dabbin.jpg",
        "image/Nevada-_holes_for_horizontal_poles_in_a_walls_of_a_dry_wash_near_pit_for_gathering_clay.jpg",
        "image/Nevada-_pit_for_gathering_clay.jpg",
        "image/Ruinas_del_Monasterio_de_San_Martín_de_Albelda_.jpg",
        "image/Soft_boulder_clay_-_geograph.org.uk_-_1119301.jpg"
    ]

    function selectImage() {
        let choice = Math.floor(Math.random() * images.length)
        selectedImage = images[choice]
    }

    function functionOne() {
        setInterval(selectImage, 2000);
    }

    functionOne()
</script>

<svelte:head>
    <title>{pageLabel}</title>
</svelte:head>

<h1>Scratchings</h1>
<div class="backgroundImage" style="background-image: url({selectedImage})"/>

<style>
    h1 {
        margin-top: 10rem;
        font-size: 20vw;
        z-index: 1;
        mix-blend-mode: difference;
    }
    .backgroundImage {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 0;
    }
</style>