function fontSelect() {
  let chance = Math.random();
  if (chance > 0.8) {
    return "UnifrakturMaguntia";
  } else {
    return "Kaisei Decol";
  }
}

function colorSelect() {
  let chance = Math.random();
  if (chance > 0.8) {
    return "red";
  } else {
    return "white";
  }
}

function transformSelect() {
  let chance = Math.random();
  if (chance > 0.8 && chance < 0.9) {
    return "lowercase";
  } else if (chance > 0.9) {
    return "uppercase";
  } else {
    return "none";
  }
}

function fontRandomise(string) {
  let splitString = string.split(" ");
  let retString = "";
  for (let i in splitString) {
    retString += `<span style="font-family: ${fontSelect()}; color: ${colorSelect()}; text-transform: ${transformSelect()}">${
      splitString[i]
    } </span>`;
  }
  return retString;
}

export { fontRandomise };
