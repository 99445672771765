<script>
    import { fontRandomise } from "../functions/stringWrangle"
    export let pageLabel;

    let trackOne = "";
    let trackTwo = "";
    let trackThree = "";
    let trackFour = "";
    let trackFive = "";
    let trackSix = "";

    let variable


    function functionOne() {
        variable = setInterval(fuckTextUp, 1000);
    }

    function fuckTextUp() {
        trackOne = fontRandomise(tracks[0].lyrics)
        trackTwo = fontRandomise(tracks[1].lyrics)
        trackThree = fontRandomise(tracks[2].lyrics)
        trackFour = fontRandomise(tracks[3].lyrics)
        trackFive = fontRandomise(tracks[4].lyrics)
        trackSix = fontRandomise(tracks[5].lyrics)
    }

    let tracks = [
        { name: "Look What I Made Me Do", lyrics: "Vapid and estranged <br/> Can’t keep your story straight <br/> You think you have it made <br/> As you return unrestrained <br/> <br/> Sculpting demons with my pain<br/> Anguish will outlive my brain<br/> All this trudging through the way<br/> Your path was clear it’s just a game <br/> <br/> It’s just a game to you <br/> I feel uncomfortable <br/> Not just a claim to trust<br/> Look what I made me do <br/> <br/> I tell myself I won’t share your traits <br/> Cause I know I can’t leave it to fate<br/> What you do when you rearrange <br/> Interpretations of what you say <br/> Make them feel like they’re to blame <br/> And it’s a crying shame <br/> The way you shift your rage<br/> You feel you can’t contain <br/> <br/> It’s just a game to you <br/> I feel uncomfortable <br/> Not just a claim to trust<br/> Look what I made me do <br/> <br/> I think you think you’re better than us <br/> But you’ll know better when you learn to adjust <br/> With your feelings in flux<br/> You’ll have to trust just once<br/> <br/> When it all falls down <br/> See who watches now <br/> Since you’ve lost the crown<br/> What you do ain’t so profound <br/> What you do ain’t so profound<br/> <br/> I think you think you’re better than us <br/> But you’ll know better when you learn to adjust <br/> With your feelings in flux<br/> You’ll have to trust just once<br/> <br/> When it all falls down <br/> See who watches now <br/> Since you’ve lost the crown<br/> What you do ain’t so profound x2<br/> <br/> What you do ain’t so profound x2<br/> "},
        { name: "Face It", lyrics: "I won’t be a fool no more <br/> It’s time to settle the score x8<br/> <br/> And I’ve been waiting <br/> Told you I was patient<br/> No communication <br/> No time was wasted <br/> Left you to chase it <br/> You were just baseless<br/> But you turned around to face it<br/> <br/> When it’s time you saw yourself <br/> Will your reflection really help <br/> Casting shadows on the wall<br/> That never reach above shelf<br/> <br/> It’s time x4<br/> <br/> To face it <br/> <br/> When it’s time you saw yourself <br/> Will your reflection really help <br/> Casting shadows on the wall<br/> That never reach above shelf x2<br/> <br/> It’s time to face it <br/> To face it<br/> "},
        { name: "Concrete", lyrics: "I’m not doing okay and I don’t know how to feel about it<br/> Mum said this one was personal, that’s one way around it<br/> Trick me once play with me twice I’ll find a way to doubt it <br/> Easier to leave the status quo, learned helpless got my mind clouded x2<br/> <br/> You just don’t think <br/> Before you speak<br/> It makes me weak <br/> The lies you preach  <br/> <br/> Pray for critique <br/> Cause I won’t Concede <br/> And if you’re between <br/> You’ll come up beneath<br/> <br/> You just don’t think <br/> Before you speak<br/> It makes me weak <br/> The lies you preach  <br/> <br/> Pray for critique <br/> Cause I won’t Concede <br/> And if you’re between <br/> You’ll come up beneath<br/> <br/> Beg for a leash  <br/> I’ll bleed to believe<br/> Drain my thoughts like a leech<br/> Don’t give me peace x2<br/> <br/> Back away from me  <br/> I know what you need<br/> I won’t give it for free<br/> I’ll lose <br/> <br/> Back away <br/> Back away<br/> Back away from me <br/> <br/> Back away from me  <br/> I know what you need<br/> I won’t give it for free<br/> Our fate is concrete x2 <br/> "},
        { name: "Mantra", lyrics: "Trying to cement my sense of self <br/> What I want and how to help <br/> Now I’m alone again cause I <br/> Just can’t keep a social life <br/> <br/> But I won’t be mistaken<br/> I won’t be misshapen<br/> I won’t let this shape me<br/> I won’t let this break me <br/> I won’t let this change me <br/> I won’t let this phase me <br/> You can never erase me x4 <br/> <br/> I’ve been looking all this time <br/> For missing pieces of my mind <br/> Memories my brain’s deprived <br/> Guess I’m glad to be alive x3<br/> <br/> You can never erase me <br/> You can never erase me <br/> You can never erase me <br/> You can never erase me <br/> You can never erase me <br/> You can never erase me <br/> You can never erase me <br/> You can never erase me <br/> "},
        { name: "Body (feat. Japes)", lyrics: "Comf<br/> <br/> I can’t replace <br/> I’ll still remain<br/> Just the same<br/> As I’ve ever been<br/> <br/> It gets so hard<br/> When you tear apart  <br/> Everything you are<br/> A new body to start <br/> <br/> Japes<br/> <br/> To keep it safe <br/> You’ll learn your place<br/> If you want somebody could <br/> Take your face<br/> And attach it to<br/> Their own point of view<br/> To broadcast over the <br/> Complications of you <br/> <br/> Comf<br/> <br/> You say you’ll never leave, <br/> You’ll never let me breath x6 <br/> <br/> Japes <br/> <br/> Sit down never listen<br/> Compare and comprehend<br/> Sit down your cell alone<br/> Fingers peeled to the bone<br/> At the core of your mind<br/> People’s thoughts that define<br/> The rhythm that you breathe<br/> Know that you'll never leave<br/> <br/> You’ll never leave <br/> <br/> Comf<br/> <br/> I don’t wanna be in your control x2<br/> <br/> You say you’ll never leave, <br/> You’ll never let me breath x2<br/> "},
        { name: "Mould Me (prod. KiiDotCom)", lyrics: "And I know that’s what you like <br/> So I’ll do it every time<br/> Hands and knees I’ll clean the grime<br/> While I tell you I’m alright<br/> <br/> I don’t want you here <br/> When you’ve just been there<br/> Well I guess I’m scared<br/> Scared enough to choose me<br/> <br/> And I know that’s what you like <br/> So I’ll do it every time<br/> Hands and knees I’ll clean the grime<br/> While I tell you I’m alright<br/> <br/> Blank slate <br/> Soft clay <br/> Mould me<br/> into place <br/> Any way <br/> My limbs won’t break, <br/> My mind won’t waste x 2 <br/> <br/> My mind won’t waste x2 <br/> <br/> If we switched our places <br/> You’d take my grazes <br/> And I’d earn your graces <br/> But we’d both end up faceless <br/> And we’d both remain nameless<br/> If my mind had been masoned with coarse stone <br/> I could ensure I stick to my mould <br/> Dented edges to protect me from rules you impose <br/> I was never one to oppose<br/> As our feelings grow cold <br/> As our bodies disrobe<br/> Two people tryna unfold we were just overexposed <br/> <br/> Day got long  <br/> Time moved on <br/> I lost it <br/> Tried to be strong, to be strong<br/> Tried to let myself be mad<br/> Cause I just couldn’t understand <br/> Cause I’m used to feeling numb <br/> I leave everyone I love <br/> <br/> I’ve been working on myself<br/> Wanted to be somebody else <br/> That’s what you wanted from me <br/> That’s what you wanted from me x3<br/> "}
    ]

    functionOne()

</script>

<svelte:head>
    <title>{pageLabel}</title>
</svelte:head>

<h1>Scrawl</h1>

<trackContainer>
    <h1 class="trackTitle">{tracks[0].name}</h1>
    <p class="trackText">{@html trackOne}</p>
</trackContainer>

<trackContainer>
    <h1 class="trackTitle">{tracks[1].name}</h1>
    <p class="trackText">{@html trackTwo}</p>
</trackContainer>

<trackContainer>
    <h1 class="trackTitle">{tracks[2].name}</h1>
    <p class="trackText">{@html trackThree}</p>
</trackContainer>

<trackContainer>
    <h1 class="trackTitle">{tracks[3].name}</h1>
    <p class="trackText">{@html trackFour}</p>
</trackContainer>

<trackContainer>
    <h1 class="trackTitle">{tracks[4].name}</h1>
    <p class="trackText">{@html trackFive}</p>
</trackContainer>

<trackContainer>
    <h1 class="trackTitle">{tracks[5].name}</h1>
    <p class="trackText">{@html trackSix}</p>
</trackContainer>

<style>
    h1 {
        margin-top: 10rem;
        font-size: 20vw;
    }
    .trackTitle {
        margin-top: 5rem;
        font-size: 5rem;
    }
    .trackText {
        font-size: 2rem;
    }
    trackContainer {
        width: 80vw;
        text-align: center;
    }

    @media only screen and (max-width: 600px) {
        .trackText {
            font-size: 1rem;
        }
    }
</style>